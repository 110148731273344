import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby-plugin-modal-routing"
import Layout from "../components/Layout"
import FluidImage from "../components/FluidImage"
import Masonry from "react-masonry-css"
import LinkIcon from "../images/components/link.svg"
import Video from "../images/components/video.svg"
import ReactMarkdown from "react-markdown"

export class WorksTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { edges: works } = data.allMarkdownRemark

    return (
      <Masonry
        breakpointCols={{
          default: 3,
          640: 2,
          480: 1
        }}
        className="works__grid"
        columnClassName="works__column"
      >
        {works &&
          works.map(({ node: work }) => (
            <div key={work.id} className="works__work">
              {work.frontmatter.image ? (
                <Link to={work.fields.slug} asModal>
                  <FluidImage
                    image={work.frontmatter.image}
                    alt={work.frontmatter.title}
                  />
                </Link>
              ) : null}
              {work.frontmatter.link && (
                <a
                  href={work.frontmatter.link}
                  className={`works__button works__button--link ${work
                    .frontmatter.linkVideo && "works__button--link-video"}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {work.frontmatter.linkVideo ? <Video /> : <LinkIcon />}
                </a>
              )}
              <div className="works__information">
                <h2>
                  {work.frontmatter.title ? work.frontmatter.title : null}
                </h2>
                <div class="works__description">
                  <ReactMarkdown
                    source={work.frontmatter.description}
                    renderers={{
                      link: props => (
                        <a
                          href={props.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {props.children}
                        </a>
                      )
                    }}
                  />
                  <ReactMarkdown
                    source={work.frontmatter.description_secondary}
                    renderers={{
                      link: props => (
                        <a
                          href={props.href}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {props.children}
                        </a>
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
      </Masonry>
    )
  }
}

const Works = ({ data }) => {
  return (
    <Layout className="works" scroll>
      <WorksTemplate data={data} />
    </Layout>
  )
}

export default Works

export const pageQuery = graphql`
  query WorksTemplate {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___id] }
      filter: { frontmatter: { templateKey: { eq: "works-work" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            id
            title
            description
            description_secondary
            templateKey
            date(formatString: "YYYY")
            link
            linkVideo
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
